import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Radium from "radium";
import { GoDotFill } from "react-icons/go";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillCloseSquare } from "react-icons/ai";
import { FiArrowUpRight } from "react-icons/fi";


const links = [
  {
    title: "Featured",
    path: "/",
  },
  {
    title: "About Us",
    path: "/aboutus",
  },
  {
    title: "Contact",
    path: "/contactus",
  },
];
export const Navbar = () => {
  const RadiatingNavLink = Radium(NavLink);
  const [navbar, setNavbar] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const ChangeNavbar = () => {
    if (window.scrollY >= 70) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", ChangeNavbar);

  return (
    <>
      <div className={"fixed w-full z-10"}>
        {/* Desktop Nav */}
        <div className="bg-white flex justify-between md:hidden items-center pl-0 pr-0 lg:pr-0 h-[80px] lg:h-[80px] border-[1px] border-b-[#cec9c8] shadow-xl sm:hidden">
          {/* Logo */}
          <div className="w-[15%] lg:w-[28%] h-full flex items-center">
            <Link to={"/"}>
              <img src={`../images/logo.png`} alt="logo" className="w-full" />
            </Link>
          </div>
          {/* Links */}
          <div className="flex justify-end w-[40%] lg:w-[60%]">
            {links.map((link, i) => {
              if (i === links.length - 1) {
                return (
                  <RadiatingNavLink
                    to={link.path}
                    style={({ isActive }) => {
                      return {
                        backgroundColor: isActive ? "#777271" : "white",
                        color: isActive ? "white" : "#777271",
                        borderLeft: isActive ? "" : "1px solid #cec9c8",
                        ":hover": { backgroundColor: "black" },
                        height: "100%",
                      };
                    }}
                    key={i}
                  >
                    <p
                      
                      className="items-center pt-[28.5px] pb-6 w-[160px] flex justify-center gap-2 text-center uppercase tracking-[1px] text-[16px] lg:text-[13px] hover:bg-[#F5F5F5] hover:text-black"
                    >
                      <GoDotFill fontSize={"25px"} />
                      {link.title}
                    </p>
                  </RadiatingNavLink>
                );
              } else {
                return (
                  <RadiatingNavLink
                    to={link.path}
                    style={({ isActive }) => {
                      return {
                        backgroundColor: isActive ? "#777271" : "white",
                        color: isActive ? "white" : "#777271",
                        borderLeft: isActive ? "" : "1px solid #cec9c8",
                        ":hover": { backgroundColor: "black" },
                      };
                    }}
                    key={i}
                  >
                    <p
                      
                      className="items-center w-[160px] pt-[28.5px] pb-6 flex justify-center text-center uppercase tracking-[1px] text-[16px] lg:text-[13px] hover:bg-[#F5F5F5] hover:text-black"
                    >
                      {link.title}
                    </p>
                  </RadiatingNavLink>
                );
              }
            })}
          </div>
        </div>
      </div>
      {/* mobile Navbar */}
      <div className="hidden md:flex sm:flex border sm:pl-5 md:justify-around sm:pr-5 gap-40 items-center w-[100%] bg-white h-[80px] fixed z-[10]">
        {/* logo */}
        <div className="sm:w-[100%] md:w-[50%]">
          <Link to={"/"}>
            <img src={`../images/logo.png`} alt="logo" className="w-full" />
          </Link>
        </div>
        <div className="text-[#777271] text-[25px] md:text-[30px]" onClick={handleShowNavbar}>
          <GiHamburgerMenu />
        </div>
      </div>
      {/* mobile Menue */}
      <div className={`nav-elements ${showNavbar && "activeSidebar"}`}>
        <div className="">
          <div
            className="grid justify-items-end pr-5 pt-5"
            onClick={handleShowNavbar}
          >
            <AiFillCloseSquare color="#777271" fontSize={"38px"} />
          </div>
          <div className="grid gap-10 mt-5">
            {links.map((link, i) => {
              return (
                <RadiatingNavLink
                  to={link.path}
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "black" : "#777271",
                    };
                  }}
                  key={i}
                >
                  <div className="border-b-[#1E1A1A] border-b-[1px] flex items-center text-[36px] pb-5">
                    <p
                      
                      className="flex uppercase tracking-[1px] ml-5"
                      onClick={handleShowNavbar}
                    >
                      {link.title}
                    </p>
                    <FiArrowUpRight />
                  </div>
                </RadiatingNavLink>
              );
            })}
          </div>
          <div className="mt-20">
            <div className="w-[70%] ml-2">
              <img src={`../images/logo.png`} alt="logo" className="w-full" />
            </div>
            <p className="text-[#FC3532] text-[24px] ml-2 font-[syne-ttf]">
            deepakk@artificialreality.us
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useState } from "react";
import { Footer } from "../components/Footer";
import { Fade } from "react-reveal";
import { Spinner } from "@chakra-ui/react";
import {
  useToast,
} from "@chakra-ui/react";


const footerContent = [
  { title: "ABOUT US", href: "/aboutus" },
  { title: "HOME", href: "/" },
];
export const ContactUs = () => {
  const toast = useToast();
  const [form, setForm] = useState({
    name: "",
    email: "",
    company: "",
    location: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  
  const getFormData = (e) => {
    let name, value;
    name = e.target.name;
    value = e.target.value;
    setForm({ ...form, [name]: value });
  };
  // const sendEmail = (e) => {
  //   e.preventDefault();
  //   console.log(form);

  // };
  const sendEmail = (e) => {
    e.preventDefault();
    const { name, email, company, location, message } = form;
    let body = `Name : ${name} ${"\n\n"}Email : ${email} ${"\n\n"}Company : ${company} ${"\n\n"}Location : ${location} ${"\n\n"}Message : ${message}`;

    let postObj = {
      subject: "Get In Touch (Artificial Reality US)",
      body: body,
    };
    //  console.log(body)
    let post = JSON.stringify(postObj);
    const url = "https://artec.co.in:8084/api/external/SendEmail";
    let xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
    xhr.send(post);
    setLoading(true);
    xhr.onload = function () {
      if (xhr.status === 200) {
        toast({
          title: `Thank you for your email. 
            We will contact you shortly.`,
          status: "success",
          isClosable: true,
          position: "top",
        })
        setLoading(false);
        setForm({
          name: "",
          email: "",
          company: "",
          location: "",
          message: "",
        });
      }
    };
  };
  return (
    <div className="pt-[80px]">
      <div className="flex p-10 justify-around lg:grid">
        <Fade bottom cascade>
          <div className="w-[50%] lg:w-full">
            <p className="text-[72px] font-[500] sm:text-[24px]">
              CONNECT WITH US
            </p>
            <p className="text-[24px] font-[500] sm:text-[14px]">
              To get a quote // for a Demo // to ask any questions{" "}
            </p>
          </div>
        </Fade>
        <Fade bottom>
          <div className="h-full grid justify-items-start w-[50%] lg:w-full sm:block">
            <p className="text-[red] text-[44px] mt-10 sm:text-[24px] sm:mt-5 font-[syne-ttf]">
            deepakk@artificialreality.us
            </p>
          </div>
        </Fade>
      </div>
      <Fade bottom>
        <div className="border-t-[1px] border-b-[1px] border-[#ABA6A5] p-10 lg:p-10 flex justify-items-end sm:block sm:p-5">
          <p className="w-[50%] text-[24px] font-[500] sm:w-full sm:text-[15px]">
          20 West 20th Street, <br /> Suite 900 New York, NY 10011, <br /> United States
          </p>
          <p className="w-[50%] text-[24px] font-[500] sm:w-full sm:text-[15px]">
          We promise to deliver a wonderful experience, where creativity meets excellence, to bring your vision to life.
          </p>
        </div>
      </Fade>
      {/* Form */}
      <div className="p-10 flex sm:grid gap-10 lg:p-5 lg:justify-between">
        <Fade bottom>
          <div className="w-[50%] grid gap-2 lg:w-[50%] sm:w-full">
            <form onSubmit={sendEmail}>
              <p className="text-[20px] font-[500] placeholder:p-5 sm:text-[16px]">
                Your Name
              </p>
              <input
                type="text"
                placeholder="Your Name"
                name="name"
                value={form.name}
                onChange={getFormData}
                className="w-full h-[40px] bg-[#F5F5F5] placeholder:p-5"
                required
              />
              <p className="text-[20px] font-[500] sm:text-[16px]">Email</p>
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={form.email}
                onChange={getFormData}
                className="w-full h-[40px] bg-[#F5F5F5] placeholder:p-5"
                required
              />
              <p className="text-[20px] font-[500] sm:text-[16px]">Company</p>
              <input
                type="text"
                placeholder="Company"
                name="company"
                value={form.company}
                onChange={getFormData}
                className="w-full h-[40px] bg-[#F5F5F5] placeholder:p-5"
                required
              />
              <p className="text-[20px] font-[500] sm:text-[16px]">Location</p>
              <input
                type="text"
                placeholder="Location"
                name="location"
                value={form.location}
                onChange={getFormData}
                className="w-full h-[40px] bg-[#F5F5F5] placeholder:p-5"
                required
              />
              <p className="text-[20px] font-[500] sm:text-[16px]">Message</p>
              <input
                type="text"
                placeholder="Few words"
                name="message"
                value={form.message}
                onChange={getFormData}
                className="w-full h-[250px] bg-[#F5F5F5] placeholder:p-5"
                required
              />
              {/* <button className="border w-[20%] lg:w-[50%] mt-5 border-black rounded-[15px] text-[20px] font-[400] hover:bg-[red] hover:border-[red] hover:text-white"
            onClick={postData}
            >
              {loading ? "Wait" : 'Submit'}
            </button> */}
              {loading ? (
                <div className="mt-5 w-[20%] flex justify-center">
                  <Spinner
                    size="xl"
                    thickness="5px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="red"
                  />
                </div>
              ) : (
                <input
                  type="submit"
                  className="border w-[20%] lg:w-[50%] mt-5 border-black rounded-[15px] text-[20px] font-[400] hover:bg-[red] hover:border-[red] hover:text-white cursor-pointer"
                />
              )}
            </form>
          </div>
        </Fade>
        <Fade bottom>
          <div className="w-[50%] lg:w-[50%] sm:w-full">
            <img src="./images/contact/car.jpg" alt="car" className="w-full" />
          </div>
        </Fade>
      </div>
      <Footer data={footerContent}/>
    </div>
  );
};
